import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PageSection from './../page-section/PageSection';

import {
  filterPageData,
  createMarkup,
  getImageData,
} from '../../../helpers/helpers';

import { PRODUCTS_PAGE_ID } from '../../../common/constants';

import styles from './Products.module.sass';

const Products = ({ result }) => {
  const { t } = useTranslation();
  const [extra, setExtra] = useState(false);
  const pageData = filterPageData(result, PRODUCTS_PAGE_ID);

  const { url, sizeClass } = getImageData(pageData);

  const [first, second] = pageData.content.rendered.split('<hr />');

  const toggleExtra = () => setExtra((extra) => !extra);

  return (
    <PageSection backgroundColor="green">
      <div
        id="products"
        className="columns is-marginless fullHeightColumns revers-columns"
      >
        <div className={`column is-paddingless is-half`}>
          <div className={styles.imageHolder}>
            <img src={url} alt="products" className={sizeClass} />
            {extra && (
              <div className={`is-hidden-mobile ${styles.extraContent}`}>
                <div
                  className={`${styles.closeButtonDesktop}`}
                  onClick={toggleExtra}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <div dangerouslySetInnerHTML={createMarkup(second)}></div>
              </div>
            )}
          </div>
        </div>
        <div className="column is-paddingless">
          <div className={styles.textContainer}>
            <h2>{pageData.title.rendered}</h2>
            <div dangerouslySetInnerHTML={createMarkup(first)}></div>
            <button
              type="button"
              className={styles.textButton}
              onClick={toggleExtra}
            >
              {t('so use it correctly')}{' '}
              <FontAwesomeIcon
                icon={faArrowRight}
                className={styles.arrowIcon}
              />
            </button>
          </div>
          {extra && (
            <div className={`is-hidden-tablet ${styles.extraContent}`}>
              <div className={`${styles.closeButton}`} onClick={toggleExtra}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div dangerouslySetInnerHTML={createMarkup(second)}></div>
            </div>
          )}
        </div>
      </div>
    </PageSection>
  );
};

Products.propTypes = {
  result: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Products;
