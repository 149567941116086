import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-scroll';

import PageSection from '../page-section/PageSection';

import {
  filterPageData,
  createMarkup,
  getImageData,
} from '../../../helpers/helpers';

import { HERO_PAGE_ID } from '../../../common/constants';

import styles from './Hero.module.sass';

const Hero = ({ result }) => {
  const { t } = useTranslation();
  const pageData = filterPageData(result, HERO_PAGE_ID);
  const [first, second] = pageData.content.rendered.split('<hr />');
  const { url, sizeClass } = getImageData(pageData);

  console.log(url, sizeClass);

  return (
    <PageSection backgroundColor="green">
      <div
        id="top"
        className="columns is-marginless fullHeightColumns revers-columns"
      >
        <div className="column is-half is-paddingless">
          <div className={styles.content}>
            <div
              className={styles.heroTitle}
              dangerouslySetInnerHTML={createMarkup(first)}
            ></div>
            <div
              className={styles.heroSubTitle}
              dangerouslySetInnerHTML={createMarkup(second)}
            ></div>
            <Link
              className={styles.moreLink}
              to="compost"
              spy={true}
              smooth={true}
              duration={500}
            >
              {t('more info')}
            </Link>
          </div>
        </div>
        <div className={`column is-half is-paddingless`}>
          <div className={styles.imageHolder}>
            <img src={url} alt="hero" className={sizeClass} />
          </div>
        </div>
      </div>
    </PageSection>
  );
};

Hero.propTypes = {
  result: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Hero;
