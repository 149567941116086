import React, { useContext } from 'react';
// import PropTypes from 'prop-types';

import Fetcher from './../../fetcher/Fetcher';

import Header from './../../header/Header';
import Hero from './../hero/Hero';
import AboutUs from './../about-us/AboutUs';
import Products from './../products/Products';
import Hemp from './../hemp/Hemp';
import Contact from './../contact/Contact';
import Compost from './../compost/Compost';
import Granulate from './../granulate/Granulate';
import ProductList from './../product-list/ProductList';
import Footer from './../../footer/Footer';
import Manufacturing from './../manufacturing/Manufacturing';
import WhereWeCanMeet from './../where-we-can-meet/WhereWeCanMeet';
import Talking from './../talking/Talking';

import { AppContext } from './../../app/AppContext';

const Home = (props) => {
  const { language } = useContext(AppContext);

  return (
    <div>
      <Fetcher lang={language}>
        {(isLoading, isError, pagesData, productsData) => (
          <>
            {isLoading && (
              <div className="pageloader is-active">
                <span className="title">Vilhemp loading...</span>
              </div>
            )}
            {/* {isError && !isLoading && <div>Error</div>} */}
            {pagesData.length > 0 && !isLoading && (
              <>
                <Header products={productsData} />
                <Hero result={pagesData} />
                <Hemp result={pagesData} />
                <Compost result={pagesData} />
                <Granulate result={pagesData} />
                <Manufacturing result={pagesData} />
                <Products result={pagesData} />
                <ProductList list={productsData} />
                <WhereWeCanMeet result={pagesData} />
                <AboutUs result={pagesData} />
                <Talking result={pagesData} />
                <Contact result={pagesData} />
                <Footer />
              </>
            )}
          </>
        )}
      </Fetcher>
    </div>
  );
};

Home.propTypes = {};

export default Home;
