import { call, put, takeLatest, all } from "redux-saga/effects";

import { FETCH_PAGES } from "./../store/constants";

import { doFetchPagesSuccess, doFetchPagesError } from "./../actions/pages";

import { getPages, getProducts } from "./../api/vilhemp";

export function* getPagesSaga(action) {
  try {
    const [pages, products] = yield all([
      call(getPages, action.payload),
      call(getProducts, action.payload),
    ]);

    const data = {
      pages: pages.data,
      products: products.data,
    };

    yield all([put(doFetchPagesSuccess(data))]);
  } catch (error) {
    console.log(error);
    yield all([put(doFetchPagesError(error))]);
  }
}

export function* watchGetPages() {
  yield takeLatest(FETCH_PAGES, getPagesSaga);
}
