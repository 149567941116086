import { createSelector } from "reselect";

const pagesState = (state) => state.pages;

export const loading = createSelector([pagesState], (state) => state.loading);

export const error = createSelector([pagesState], (state) => state.error);

export const pages = createSelector([pagesState], (state) => state.pages);

export const products = createSelector([pagesState], (state) => state.products);
