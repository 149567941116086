import React, { useRef, useState } from 'react';
/* import PropTypes from "prop-types"; */
import { Formik, Field, Form } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import InputField from './InputField/InputField';
import TextareaField from './TextareaField/TextareaField';

import styles from './ContactForm.module.sass';

import {
  /* validateCaptcha,
  postToHubspot, */
  sendEmail,
} from './../../../api/vilhemp';

const siteKey = '6Lf6TN4ZAAAAAD_QqIsTfE0y4_INPqS3tfx1kqfe';

const contactSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, 'Túl rövid')
    .max(50, 'Túl hosszú')
    .required('Kérjük töltse ki.'),
  lastname: Yup.string()
    .min(2, 'Túl rövid')
    .max(50, 'Túl hosszú')
    .required('Kérjük töltse ki.'),
  email: Yup.string()
    .email('Nem megfelelő e-mail')
    .required('Kérjük töltse ki.'),
  message: Yup.string().min(10, 'Túl rövid').required('Kérjük töltse ki.'),
});

const ContactForm = (props) => {
  const { t } = useTranslation();
  const recaptchaRef = useRef(null);
  const [sent, setSent] = useState(() => false);
  const [message, setMessage] = useState(() => null);
  const [sending, setSending] = useState(false);

  return (
    <div className={styles.formContainer}>
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          message: '',
        }}
        validationSchema={contactSchema}
        onSubmit={async (values) => {
          setSending(true);
          const token = await recaptchaRef.current.executeAsync();

          const emailToPost = {
            ...values,
            recaptcha: token,
          };

          sendEmail(emailToPost).then((result) => {
            setMessage(() => result.data);
            setSent(() => true);
            setSending(false);
            setTimeout(() => setSent(() => false), 20000);
          });

          /* setSending(true);
          const token = await recaptchaRef.current.executeAsync();

          const emailToPost = {
            recaptcha: token,
          };

          validateCaptcha(emailToPost)
            .then((result) => {
              const data = {
                fields: [
                  {
                    name: 'firstname',
                    value: values.firstname,
                  },
                  {
                    name: 'lastname',
                    value: values.lastname,
                  },
                  {
                    name: 'email',
                    value: values.email,
                  },
                  {
                    name: 'message',
                    value: values.message,
                  },
                ],
              };

              postToHubspot(data).then((result) => {
                console.log(result);
                setSending(false);
                setMessage(() => ({
                  isOk: true,
                  message: result.data.inlineMessage,
                }));
                setSent(() => true);

                setTimeout(() => setSent(() => false), 10000);
              });
            })
            .catch((error) => {
              console.log(error);
              setSending(false);
            }); */
        }}
      >
        <Form>
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <div className="control is-expanded">
                  <Field
                    id="firstname"
                    name="firstname"
                    placeholder={t('firstname')}
                    component={InputField}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control is-expanded">
                  <Field
                    id="lastname"
                    name="lastname"
                    placeholder={t('lastname')}
                    component={InputField}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field is-expanded">
                <div className="control is-expanded">
                  <Field
                    component={InputField}
                    id="email"
                    name="email"
                    placeholder="E-mail"
                    type="email"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field is-expanded">
                <div className="control is-expanded">
                  <Field
                    id="message"
                    name="message"
                    placeholder={t('message')}
                    component={TextareaField}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* formik */}

          <button
            type="submit"
            className={`${styles.submitButton} ${
              sending ? styles.disabled : ''
            }`}
            disabled={sending}
          >
            Küldés
          </button>
          {sent && (
            <div
              className={`${styles.messageBase} ${
                message.isOk ? styles.ok : styles.nook
              }`}
            >
              {message.submit_message}
            </div>
          )}
        </Form>
      </Formik>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={siteKey}
        badge="inline"
      />
    </div>
  );
};

ContactForm.propTypes = {};

export default ContactForm;

// message.isOk ? styles.ok :
