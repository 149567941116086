import React from 'react';
import PropTypes from 'prop-types';

import SimpleSection from '../simple-section/SimpleSection';

const productMapper = (item, index) => {
  return <SimpleSection key={index} item={item} reversed={index % 2 === 0} />;
};

const ProductList = ({ list }) => {
  const products = list.map(productMapper);

  return <>{products}</>;
};

ProductList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProductList;
