import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import PageSection from './../page-section/PageSection';

import { createMarkup, getImageData } from '../../../helpers/helpers';

import styles from './SimpleSection.module.sass';

const SimpleSection = ({ item, reversed }) => {
  const content = R.path(['content', 'rendered'], item);
  const title = R.path(['title', 'rendered'], item);
  const slug = R.path(['slug'], item);
  const { alt, url, sizeClass } = getImageData(item);

  const webShopLink = R.path(['acf', 'webshop_link'], item);

  console.log(webShopLink)

  const reversedClass = reversed ? styles.reverse : '';
  const color = reversed ? 'green' : 'darkGreen';
  const contentClass = reversed ? styles.normal : styles.reversed;
  const right = alt === 'right' ? styles.rightImage : '';
  const shopLinkVersion = reversed ? styles.shopLink : styles.revShopLink;

  return (
    <PageSection backgroundColor={color}>
      <div
        id={slug}
        className={`columns is-marginless fullHeightColumns ${reversedClass}`}
      >
        <div className="column is-paddingless is-half">
          <div className={`${styles.imageHolder} ${right}`}>
            <img src={url} alt="products" className={sizeClass} />
          </div>
        </div>
        <div className="column is-paddingless is-half">
          <div className={styles.textContainer}>
            <h2>{title}</h2>
            <div
              className={contentClass}
              dangerouslySetInnerHTML={createMarkup(content)}
            ></div>
            {webShopLink && <a className={shopLinkVersion} href={webShopLink}>Vásárlás</a>}
          </div>
        </div>
      </div>
    </PageSection>
  );
};

SimpleSection.propTypes = {
  item: PropTypes.object.isRequired,
  reversed: PropTypes.bool.isRequired,
};

export default SimpleSection;
