import React from "react";
import PropTypes from "prop-types";

import styles from "./PageSection.module.sass";

const PageSection = ({ backgroundColor, children }) => {
  return (
    <section
      className={`section is-paddingless ${styles.fullheight} ${styles[backgroundColor]}`}
    >
      <div className="container is-fluid is-paddingless">{children}</div>
    </section>
  );
};

PageSection.propTypes = {
  backgroundColor: PropTypes.string,
};

export default PageSection;
