import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import eicLogo from './eit-climate.png';
import hiventuresLogo from './logo-hiventures.png';
import nkfia from './nkfia.png';
import hazaiLogo from './ht.png';
import sparLogo from './spar-hc.png';
import profiKomp from './profikomp.png';
import taOkiLogo from './ta-oki.png';
import gbLogo from './gb-hun.png';

import styles from './Footer.module.sass';

import { AppContext } from './../app/AppContext';

const Footer = (props) => {
  const { language } = useContext(AppContext);

  return (
    <section className={`section ${styles.darkFooter}`}>
      <div className={`container is-fluid ${styles.footerContent}`}>
        <div className={styles.logoGrid}>
          <div className={`${styles.halfGrid} ${styles.leftSide}`}>
            <h1 className={styles.footerH1}>Támogatók</h1>
            <div className={styles.Leftlogos}>
              <div className={styles.leftLogoContainer}>
                <img src={nkfia} alt=""/>
              </div>
              <div className={styles.leftLogoContainer}>
                <img src={hiventuresLogo} alt="" />
              </div>
              <div className={styles.leftLogoContainer}>
                <img src={eicLogo} alt="" />
              </div>
            </div>
          </div>
          <div className={`${styles.halfGrid} ${styles.rightSide}`}>
            <div className={styles.twoThird}>
              <h1 className={styles.footerH1}>Tanusítványok</h1>
              <div className={styles.logos}>
                <div className={styles.leftLogoContainer}>
                  <img src={taOkiLogo} alt="" />
                </div>
                <div className={`${styles.leftLogoContainer} ${styles.spacer}`}>
                  <img src={gbLogo} alt="" />
                </div>
                <div className={styles.leftLogoContainer}>
                  <img src={hazaiLogo} alt="" />
                </div>
                <div className={`${styles.leftLogoContainer} ${styles.spacer}`}>
                  <img src={sparLogo} alt="" />
                </div>
              </div>
            </div>
            <div className={styles.oneThird}>
              <h1 className={styles.footerH1}>Együttműködő  partner</h1>
                <div className={styles.leftLogoContainer}>
                  <img src={profiKomp} alt="" />
                </div> 
            </div>
          </div>
        </div>
        <div>
          &copy; 2021 Vilhemp,
          <Link
            className={styles.link}
            to={{
              pathname: `/${language}/fogyasztoitajekoztato`,
            }}
          >
            Fogyasztói tájékoztató
          </Link></div>
      </div>
    </section>
  );
};

export default Footer;
