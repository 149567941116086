import React from 'react';
// import PropTypes from 'prop-types';

import useDataApi from './../../hooks/dataApi';
import GenericPage from './../generic-page/GenericPage';
import PageHeader from './../page-header/PageHeader';

const ConsumerInformation = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setUrl] = useDataApi(
    `https://api.vilhemp.hu/wp-json/wp/v2/pages/118?lang=hu&_embed`,
    null,
  );

  return (
    <div>
      {state.isLoading && (
        <div className="pageloader is-active">
          <span className="title">Vilhemp loading...</span>
        </div>
      )}
      {state.isError && !state.isLoading && <div>Error</div>}
      {!state.isLoading && state.data && (
        <>
          <PageHeader />
          <GenericPage data={state.data} />
        </>
      )}
    </div>
  );
};

ConsumerInformation.propTypes = {};

export default ConsumerInformation;
