import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./../home/home/Home";
import ConsumerInformation from "./../consumer-information/ConsumerInformation";
import StaticPage from "./../static-page/StaticPage";

import { AppContext } from "./../app/AppContext";

const RouterContainer = (props) => {
  const { language } = useContext(AppContext);

  return (
    <Switch>
      <Redirect
        from="/fogyasztoitajekoztato"
        to={`/${language}/fogyasztoitajekoztato/`}
      />
      <Redirect
        from="/sajtokozlemeny-2022-11-22"
        to={`/${language}/sajtokozlemeny-2022-11-22`}
      />

      <Route
        path={`/:lang(en|hu)/sajtokozlemeny-2022-11-22`}
        component={StaticPage}
      />
      <Route
        path={`/:lang(en|hu)/fogyasztoitajekoztato`}
        component={ConsumerInformation}
      />
      <Route path={`/:lang(en|hu)/`} exact component={Home} />
      <Redirect to={`/${language}/`} />
    </Switch>
  );
};

RouterContainer.propTypes = {};

export default RouterContainer;
