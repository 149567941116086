import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PageSection from '../page-section/PageSection';

import { COMPOST_PAGE_ID } from '../../../common/constants';

import { filterPageData, createMarkup } from '../../../helpers/helpers';

import compostHu from './compost.svg';
import compostEn from './illustr_ENG.svg';

import styles from './Compost.module.sass';

const Compost = ({ result }) => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  const pageData = filterPageData(result, COMPOST_PAGE_ID);

  return (
    <PageSection backgroundColor="begie">
      <div
        id="compost"
        className="columns is-marginless fullHeightColumns revers-columns"
      >
        <div className="column is-paddingless is-half">
          <div className={styles.content}>
            <h2 className={styles.dark}>{pageData.title.rendered}</h2>
            <div
              className={styles.textContent}
              dangerouslySetInnerHTML={createMarkup(pageData.content.rendered)}
            ></div>
          </div>
        </div>
        <div className="column is-paddingless is-half">
          <div className={styles.imageHolder}>
            {language === 'hu' && <img src={compostHu} alt="compost" />}
            {language === 'en' && <img src={compostEn} alt="compost" />}
          </div>
        </div>
      </div>
    </PageSection>
  );
};

Compost.propTypes = {
  result: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Compost;
