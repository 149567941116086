import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

import PageSection from '../page-section/PageSection';

import {
  filterPageData,
  createMarkup,
  getImageData,
} from '../../../helpers/helpers';

import { HEMP_PAGE_ID } from '../../../common/constants';

import styles from './Hemp.module.sass';

const Hemp = ({ result }) => {
  const pageData = filterPageData(result, HEMP_PAGE_ID);
  const [show, setShow] = useState(() => false);

  const [first, second] = pageData.content.rendered.split('<hr />');

  const { url, sizeClass } = getImageData(pageData);
  // const right = alt === 'right' ? styles.rightImage : '';

  const handleShow = () => setShow(() => !show);

  return (
    <PageSection backgroundColor="darkGreen">
      <div id="hemp" className="columns is-marginless fullHeightColumns">
        <div className="column is-half is-paddingless">
          <div className={styles.imageHolder}>
            <img src={url} alt="products" className={sizeClass} />
          </div>
        </div>
        <div className="column is-paddingless">
          <div className={styles.textContainer}>
            <h2>{pageData.title.rendered}</h2>
            <div dangerouslySetInnerHTML={createMarkup(first)}></div>
            <div className={styles.arrow} onClick={handleShow}>
              {show ? (
                <FontAwesomeIcon icon={faArrowUp} />
              ) : (
                <FontAwesomeIcon icon={faArrowDown} />
              )}
            </div>
            {show && <div dangerouslySetInnerHTML={createMarkup(second)}></div>}
          </div>
        </div>
      </div>
    </PageSection>
  );
};

Hemp.propTypes = {
  result: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Hemp;
