import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

import PageSection from '../page-section/PageSection';
import ContactForm from '../contact-form/ContactForm';

import { filterPageData, createMarkup } from '../../../helpers/helpers';

import { CONTACT_PAGE_ID } from '../../../common/constants';

import styles from './Contact.module.sass';

const Contact = ({ result }) => {
  const pageData = filterPageData(result, CONTACT_PAGE_ID);

  return (
    <PageSection backgroundColor="begie">
      <div
        id="contact"
        className="columns is-marginless fullHeightColumns revers-columns"
      >
        <div className="column is-paddingless">
          <div className={styles.content}>
            <h2 className={styles.dark}>{pageData.title.rendered}</h2>
            <ContactForm />
            <div
              className={styles.textContent}
              dangerouslySetInnerHTML={createMarkup(pageData.content.rendered)}
            ></div>
            <div className={styles.contactInfo}>
              <div>Tel.: +36 30 336 6842</div>
              <div>
                email:{' '}
                <a href="mailto:info@vilhemp.hu" className={styles.mailLink}>
                  info@vilhemp.hu
                </a>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/vilhemp/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.facebook}
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-paddingless">
          <div className={styles.imageHolder}></div>
        </div>
      </div>
    </PageSection>
  );
};

Contact.propTypes = {
  result: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Contact;
