import React from 'react';
import PropTypes from 'prop-types';

import { createMarkup } from '../../helpers/helpers';

import styles from './GenericPage.module.sass';

const GenericPage = ({ data }) => {
  const title = data.title.rendered;
  const content = data.content.rendered;

  return (
    <section className="section">
      <div className="container">
        <h3 className={`title is-3 ${styles.heading}`}>{title}</h3>
        <div dangerouslySetInnerHTML={createMarkup(content)}></div>
      </div>
    </section>
  );
};

GenericPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GenericPage;
