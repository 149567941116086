import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import PageSection from '../page-section/PageSection';

import {
  filterPageData,
  createMarkup,
  getImageData,
} from '../../../helpers/helpers';

import { GRANULATE_PAGE_ID } from '../../../common/constants';

import styles from './Granulate.module.sass';

const Granulate = ({ result }) => {
  const pageData = filterPageData(result, GRANULATE_PAGE_ID);

  const content = R.path(['content', 'rendered'], pageData);
  const { url, sizeClass } = getImageData(pageData);

  return (
    <PageSection backgroundColor="begie">
      <div id="granulate" className="columns is-marginless fullHeightColumns">
        <div className="column is-paddingless is-half">
          <div className={styles.imageHolder}>
            <img src={url} alt="products" className={sizeClass} />
          </div>
        </div>
        <div className="column is-paddingless is-half">
          <div className={styles.textContainer}>
            <h2 className={styles.dark}>{pageData.title.rendered}</h2>
            <div dangerouslySetInnerHTML={createMarkup(content)}></div>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

Granulate.propTypes = {
  result: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Granulate;
