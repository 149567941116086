import React, { useEffect, useState } from "react";
import "./App.css";
import { useTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";

import RouterContainer from "./../router-container/RouterContainer";
import CustomCookieConsent from "../custom-cookie-consent/CustomCookieConsent";

import { AppContext } from "./AppContext";

const advancedMatching = { em: "some@email.com" };
const options = {
  autoConfig: true,
  debug: false,
};

function App() {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const [cookies] = useCookies(["CookieConsent"]);

  const [cookieConsent, setCookieConsent] = useState(() => {
    if (cookies.CookieConsent) {
      ReactGA.initialize([
        {
          trackingId: "G-HR6SB1PN8P",
        },
      ]);
      return true;
    }
    return false;
  });
  let location = useLocation();

  useEffect(() => {
    cookieConsent &&
      ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location, cookieConsent]);

  useEffect(() => {
    ReactPixel.init("745390299724381", advancedMatching, options);
    ReactPixel.pageView();
    ReactPixel.revokeConsent();
  }, []);

  const handleCookieAccept = () => {
    ReactPixel.grantConsent();
    ReactGA.initialize([
      {
        trackingId: "G-HR6SB1PN8P",
      },
    ]);
    setCookieConsent(true);
  };

  const handleMenuClickTrack = (menuName) => {
    cookieConsent && ReactGA.send({ hitType: "pageview", page: menuName });
  };

  return (
    <AppContext.Provider
      value={{
        language,
        handleCookieAccept,
        handleMenuClickTrack,
      }}
    >
      <>
        <RouterContainer />
        <CustomCookieConsent onGrantConsent={handleCookieAccept} />
      </>
    </AppContext.Provider>
  );
}

export default App;
