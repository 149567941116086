import React from "react";
import PropTypes from "prop-types";
import CookieConsent from "react-cookie-consent";

const CustomCookieConsent = ({ onGrantConsent }) => {
  const handleAccept = () => {
    onGrantConsent();
    //window.bp('consent', 'grantConsent');
  };

  return (
    <CookieConsent
      //debug={true}
      location="bottom"
      buttonText="Elfogadás"
      //cookieName="myAwesomeCookieName2"

      onAccept={handleAccept}
      style={{
        background: "#213e3c",
        fontSize: "12px",
        borderTop: "1px solid #ffffff",
      }}
      buttonStyle={{ color: "#213e3c", background: "white", fontSize: "12px" }}
    >
      Az oldal sütiket használ a legjobb felhasználói élmény biztosítására.
    </CookieConsent>
  );
};

CustomCookieConsent.propTypes = {
  onGrantConsent: PropTypes.func.isRequired,
};

export default CustomCookieConsent;
