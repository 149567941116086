import axios from 'axios';

const portalId = '9230371';
const formGuid = '5fbf207a-f4bd-4d71-8d92-aed2f1b99082';

export const getPage = (params) => axios(params.url);

export const getPages = (params) =>
  axios.get(
    `https://api.vilhemp.hu/wp-json/wp/v2/pages?lang=${params.lang}&_embed&per_page=100`,
  );

export const getProducts = (params) =>
  axios.get(
    `https://api.vilhemp.hu/wp-json/wp/v2/posts?categories=2&lang=${params.lang}&_embed&per_page=100`,
  );

export const sendEmail = (params) => {
  let formDataBody = new FormData();
  const formObject = {
    firstname: params.firstname,
    lastname: params.lastname,
    email: params.email,
    message: params.message,
    recaptcha: params.recaptcha,
  };

  const bodyKeys = Object.keys(formObject);

  bodyKeys.forEach((item) => {
    formDataBody.set(item, formObject[item]);
  });

  return axios.post('https://api.vilhemp.hu/contact/', formDataBody, {
    headers: { 'Content-Type': 'multipart/form-data' },
    crossDomain: true,
  });
};

export const validateCaptcha = (params) => {
  let formDataBody = new FormData();
  const formObject = {
    recaptcha: params.recaptcha,
  };

  const bodyKeys = Object.keys(formObject);

  bodyKeys.forEach((item) => {
    formDataBody.set(item, formObject[item]);
  });

  return axios.post('https://api.vilhemp.hu/recaptcha.php', formDataBody, {
    headers: { 'Content-Type': 'multipart/form-data' },
    crossDomain: true,
  });
};

export const postToHubspot = (data) => {
  return axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    data,
  );
};
