import {
  FETCH_PAGES,
  FETCH_PAGES_SUCCESS,
  FETCH_PAGES_ERROR,
} from './../store/constants';

const initalState = {
  loading: false,
  error: false,
  pages: [],
  products: [],
};

const pagesReducer = (state = initalState, action) => {
  switch (action.type) {
    case FETCH_PAGES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FETCH_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        pages: [...action.payload.pages],
        products: [...action.payload.products],
      };
    case FETCH_PAGES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default pagesReducer;
