import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { doFetchPages } from "./../../actions/pages";

import {
  loading,
  error,
  pages,
  products,
} from "./../../selectors/pages.selectors";

const Fetcher = ({ lang, children }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(loading);
  const isError = useSelector(error);
  const pageData = useSelector(pages);
  const productsData = useSelector(products);

  useEffect(() => {
    dispatch(
      doFetchPages({
        lang,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <React.Fragment>
      {children(isLoading, isError, pageData, productsData)}
    </React.Fragment>
  );
};

/* Fetcher.propTypes = {}; */

export default Fetcher;
