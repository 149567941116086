import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

import PageSection from './../page-section/PageSection';

import {
  filterPageData,
  createMarkup,
  getImageData,
} from '../../../helpers/helpers';

import { ABOUT_US_PAGE_ID } from './../../../common/constants';

import team from './csapat_web.jpg';
import styles from './AboutUs.module.sass';

const AboutUs = ({ result }) => {
  const { t, i18n } = useTranslation();
  const pageData = filterPageData(result, ABOUT_US_PAGE_ID);

  const language = i18n.language;

  const content = R.path(['content', 'rendered'], pageData);
  const { url, sizeClass } = getImageData(pageData);

  return (
    <PageSection backgroundColor="begie">
      <div id="aboutus" className="columns is-marginless fullHeightColumns">
        <div className="column is-half is-paddingless">
          <div className={`${styles.textContainer} ${styles.mobilePadding}`}>
            <h2 className={styles.dark}>{pageData.title.rendered}</h2>
            <div dangerouslySetInnerHTML={createMarkup(content)}></div>
            <div className={styles.teamImageHolder}>
              <img src={team} alt="team" className={styles.teamImage} />
            </div>
            <div className={styles.smallImages}>
              <div className={styles.nameContainer}>
                <p>{language === 'hu' ? 'Rédey Soma' : 'Soma Rédey'}</p>
                <p>{t('founder')}, COO</p>
              </div>
              <div className={styles.nameContainer}>
                <p>{language === 'hu' ? 'Fülöp Villő' : 'Villő Fülöp'}</p>
                <p>{t('founder')}, CEO</p>
              </div>
              <div className={styles.nameContainer}>
                <p>{language === 'hu' ? 'Szűts Gergely' : 'Gergely Szűts'}</p>
                <p>{t('founder')}, CFO</p>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-half is-paddingless">
          <div className={styles.imageHolder}>
            <img src={url} alt="about us" className={sizeClass} />
          </div>
        </div>
      </div>
    </PageSection>
  );
};

AboutUs.propTypes = {
  result: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AboutUs;
