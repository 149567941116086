/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

import { ReactComponent as Logo } from './../header/vilhemp_logo.svg';

import styles from './PageHeader.module.sass';

import { AppContext } from './../app/AppContext';

const Header = (props) => {
  const [open, setOpen] = useState(() => false);
  const { language } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const toggleMenu = () => setOpen(() => !open);

  const isActiveClass = open ? 'is-active' : '';

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    history.push(`/${lang}`);
  };

  return (
    <nav
      className={`navbar is-fixed-top is-primary ${styles.vilhempNav}`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link className={`navbar-item`} to={`/${language}/`}>
          <div className={styles.logo}>
            <Logo />
          </div>
        </Link>

        <a
          role="button"
          className={`navbar-burger ${isActiveClass}`}
          aria-label="menu"
          aria-expanded="false"
          onClick={toggleMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div className={`navbar-menu ${isActiveClass}`}>
        <div className="navbar-end">
          <Link
            className={`navbar-item ${styles.navItem}`}
            to={{
              pathname: `/${language}/`,
              hash: '#hemp',
            }}
          >
            {t('Hemp')}
          </Link>
          <div className="navbar-item has-dropdown is-hoverable">
            <Link
              className={`navbar-item ${styles.navItem}`}
              to={{
                pathname: `/${language}/`,
                hash: '#compost',
              }}
            >
              {t('Company policy')}
            </Link>

            <div className="navbar-dropdown">
              <Link
                className={`navbar-item ${styles.navItem}`}
                to={{
                  pathname: `/${language}/`,
                  hash: '#compost',
                }}
              >
                {t('Compost')}
              </Link>
              <Link
                className={`navbar-item ${styles.navItem}`}
                to={{
                  pathname: `/${language}/`,
                  hash: '#granulate',
                }}
              >
                {t('Granulate')}
              </Link>
            </div>
          </div>
          {/* products */}
          <div className="navbar-item has-dropdown is-hoverable">
            <Link
              className={`navbar-item ${styles.navItem}`}
              to={{
                pathname: `/${language}/`,
                hash: '#products',
              }}
            >
              {t('Products')}
            </Link>
          </div>

          <Link
            className={`navbar-item ${styles.navItem}`}
            to={{
              pathname: `/${language}/`,
              hash: '#aboutus',
            }}
          >
            {t('About us')}
          </Link>

          <Link
            className={`navbar-item ${styles.navItem}`}
            to={{
              pathname: `/${language}/`,
              hash: '#contact',
            }}
          >
            {t('Contact')}
          </Link>
          <div className="navbar-item">
            <a
              href="https://instagram.com/vilhemp"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.facebook}
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
          <div className="navbar-item">
            <a
              href="https://www.facebook.com/vilhemp/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.facebook}
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          </div>
          <div className="navbar-item">
            <a
              role="button"
              className={`navbar-item ${styles.navItem}`}
              onClick={() => handleLanguageChange('hu')}
            >
              HU
            </a>{' '}
            |{' '}
            <a
              role="button"
              className={`navbar-item ${styles.navItem}`}
              onClick={() => handleLanguageChange('en')}
            >
              EN
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
