/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Link, scroller, animateScroll } from "react-scroll";
import { useLocation, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import { AppContext } from "../app/AppContext";

import { ReactComponent as Logo } from "./vilhemp_logo_web2-01.svg";

import styles from "./Header.module.sass";

const Header = ({ products }) => {
  const [open, setOpen] = useState(() => false);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { handleMenuClickTrack } = useContext(AppContext);

  useEffect(() => {
    const scrollToElemet = location.hash.split("#")[1];
    scrollToElemet &&
      scroller.scrollTo(scrollToElemet, {
        duration: 500,
        smooth: true,
      });
  });

  const toggleMenu = () => setOpen(() => !open);

  const toTheTop = () => {
    animateScroll.scrollToTop({
      duration: 500,
      smooth: true,
    });
  };

  const isActiveClass = open ? "is-active" : "";

  const productsDropDown = products.map((item, index) => {
    const { slug, title } = item;
    return (
      <Link
        key={index}
        className={`navbar-item ${styles.navItem}`}
        to={slug}
        spy={true}
        smooth={true}
        duration={500}
        hashSpy={true}
        onClick={() => handleMenuClickTrack(title.rendered)}
      >
        {title.rendered}
      </Link>
    );
  });

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    history.push(`/${lang}`);
  };

  return (
    <nav
      className={`navbar is-fixed-top is-primary ${styles.vilhempNav}`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link className={`navbar-item`} to="" onClick={toTheTop}>
          <div className={styles.logo}>
            <Logo />
          </div>
        </Link>

        <a
          role="button"
          className={`navbar-burger ${isActiveClass}`}
          aria-label="menu"
          aria-expanded="false"
          onClick={toggleMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div className={`navbar-menu ${isActiveClass}`}>
        <div className="navbar-end">
          <Link
            className={`navbar-item ${styles.navItem}`}
            to="hemp"
            spy={true}
            smooth={true}
            duration={500}
            hashSpy={true}
            onClick={() => handleMenuClickTrack("háttér")}
          >
            {t("Hemp")}
          </Link>
          <div className="navbar-item has-dropdown is-hoverable">
            <Link
              className={`navbar-item ${styles.navItem}`}
              to="compost"
              spy={true}
              smooth={true}
              duration={500}
              hashSpy={true}
              onClick={() => handleMenuClickTrack("cégfilozófiánk")}
            >
              {t("Company policy")}
            </Link>

            <div className="navbar-dropdown">
              <Link
                className={`navbar-item ${styles.navItem}`}
                to="compost"
                spy={true}
                smooth={true}
                duration={500}
                hashSpy={true}
                onClick={() => handleMenuClickTrack("Komposzt")}
              >
                {t("Compost")}
              </Link>
              <Link
                className={`navbar-item ${styles.navItem}`}
                to="granulate"
                spy={true}
                smooth={true}
                duration={500}
                hashSpy={true}
                onClick={() => handleMenuClickTrack("Granulatum")}
              >
                {t("Granulate")}
              </Link>
            </div>
          </div>
          {/* products */}
          <div className="navbar-item has-dropdown is-hoverable">
            <Link
              className={`navbar-item ${styles.navItem}`}
              to="products"
              spy={true}
              smooth={true}
              duration={500}
              hashSpy={true}
              onClick={() => handleMenuClickTrack("Termekeink")}
            >
              {t("Products")}
            </Link>

            <div className="navbar-dropdown">{productsDropDown}</div>
          </div>

          <Link
            className={`navbar-item ${styles.navItem}`}
            to="aboutus"
            spy={true}
            smooth={true}
            duration={500}
            hashSpy={true}
            onClick={() => handleMenuClickTrack("rolunk")}
          >
            {t("About us")}
          </Link>

          <Link
            className={`navbar-item ${styles.navItem}`}
            to="contact"
            spy={true}
            smooth={true}
            duration={500}
            hashSpy={true}
            onClick={() => handleMenuClickTrack("kapcsolat")}
          >
            {t("Contact")}
          </Link>
          <a
            className={`navbar-item ${styles.navItem}`}
            href="https://webshop.vilhemp.hu"
            onClick={() => handleMenuClickTrack("webshop")}
          >
            Webshop
          </a>
          <div className="navbar-item">
            <a
              href="https://instagram.com/vilhemp"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.facebook}
              onClick={() => handleMenuClickTrack("Instagram")}
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
          <div className="navbar-item">
            <a
              href="https://www.facebook.com/vilhemp/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.facebook}
              onClick={() => handleMenuClickTrack("facebook")}
            >
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
          </div>
          <div className="navbar-item">
            <a
              role="button"
              className={`navbar-item ${styles.navItem}`}
              onClick={() => handleLanguageChange("hu")}
            >
              HU
            </a>{" "}
            |{" "}
            <a
              role="button"
              className={`navbar-item ${styles.navItem}`}
              onClick={() => handleLanguageChange("en")}
            >
              EN
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
