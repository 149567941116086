import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
  hu: {
    translation: {
      'About us': 'Rólunk',
      Products: 'Termékeink',
      Hemp: 'HÁTTÉR',
      'Company policy': 'Cégfilozófiánk',
      Compost: 'Komposzt',
      Granulate: 'Granulátum',
      Contact: 'Kapcsolat',
      'more info': 'Tudj meg többet',
      'so use it correctly': 'így használd helyesen',
      firstname: 'Keresztnév',
      lastname: 'Vezetéknév',
      subject: 'Tárgy',
      message: 'Üzenet',
      founder: 'Alapító',
    },
  },
  en: {
    translation: {
      'About us': 'About us',
      Products: 'Products',
      Hemp: 'Background',
      'Company policy': 'Company policy',
      Compost: 'Compost',
      Granulate: 'Granulátum',
      Contact: 'Contact',
      'more info': 'More info',
      'so use it correctly': 'So use it correctly',
      firstname: 'First name',
      lastname: 'Last name',
      subject: 'Subject',
      message: 'Message',
      founder: 'Founder',
    },
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    // lng: 'hu',
    fallbackLng: 'hu',
    keySeparator: false,
    lowerCaseLng: true,
    supportedLngs: ['hu', 'en'],
    interpolation: {
      escapeValue: false,
    },
    //debug: true,
    detection: {
      lookupFromPathIndex: 0,
      order: ['localStorage', 'path'],
    },
  });

export default i18n;
