import * as R from 'ramda';

export const filterPageData = (data, id) =>
  R.head(data.filter((p) => p.id === id));

export const createMarkup = (data) => ({ __html: data });

export const getImageData = (pageData) => {
  const {
    source_url,
    alt_text,
    media_details: { width, height },
  } = R.head(R.path(['_embedded', 'wp:featuredmedia'], pageData));

  const sizeClass = width > height ? 'unsetImageSizes' : '';

  return {
    alt: alt_text,
    url: source_url,
    sizeClass,
  };
};
